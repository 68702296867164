import React from 'react';
import styled from 'styled-components';

import EnquiryTabs from '../enquiry-tabs';

import heroBg from './images/background_lake_wbottle.jpg';

const StyledHero = styled.section`
	background-image: url(${heroBg});
	background-size: cover;
	background-position: left center;
	background-repeat: no-repeat;
	padding-top: 20vw;
	padding-bottom: 9.375rem;
	margin-bottom: -1rem;
	@media (max-width: 768px) {
		padding-top: 140px;
	}
`;

export default ({ copy }) => (
	<StyledHero className="section">
		<div className="container">
			<EnquiryTabs copy={copy} />
		</div>
	</StyledHero>
);
