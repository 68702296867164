import React from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ContactForm from '../contact-form';

import blackHover from './images/black-hover.svg';

import 'react-tabs/style/react-tabs.css';

const StyledTabs = styled.div`
	.react-tabs__tab-list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border-bottom: none;
		max-width: 300px;
		margin: 0 auto;

		@media (max-width: 768px) {
			align-items: center;
		}

		li {
			font-family: ${(p) => p.theme.font.display};
			font-size: 1.5rem;
			color: ${(p) => p.theme.font.dark};
			background: none;
			border: none;
			transition: color 300ms;
			padding: 0.75rem 2rem 0.5rem;
			margin-top: -0.5rem;

			&[class*='selected'],
			&:hover {
				color: ${(p) => p.theme.color.yellow};
				background-image: url(${blackHover});
				background-size: contain;
				background-position: left center;
				background-repeat: no-repeat;
			}

			&:focus {
				outline: none;
				border: none;
				box-shadow: none;

				&::after {
					display: none;
				}
			}
		}
	}
	.form-bg {
		background-color: rgba(0, 0, 0, 0.9);
	}
`;

export default ({ copy }) => {
	return (
		<StyledTabs>
			<Tabs className="columns">
				<div className="column is-4">
					<TabList>
						<Tab>General Inquiry</Tab>
						<Tab>Vendor Inquiry</Tab>
						<Tab>Distribution Inquiry</Tab>
						<Tab>Charitable Request</Tab>
					</TabList>
				</div>
				<div className="column is-8">
					<TabPanel>
						<div className="form-bg">
							<ContactForm type="general" copy={copy} />
						</div>
					</TabPanel>
					<TabPanel>
						<div className="form-bg">
							<ContactForm type="vendor" copy={copy} />
						</div>
					</TabPanel>
					<TabPanel>
						<div className="form-bg">
							<ContactForm type="distribution" copy={copy} />
						</div>
					</TabPanel>
					<TabPanel>
						<div className="form-bg">
							<ContactForm type="charity" copy={copy} />
						</div>
					</TabPanel>
				</div>
			</Tabs>
		</StyledTabs>
	);
};
