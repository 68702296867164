import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby-link';

import StyledForm from '../../../styles/form';
import { ButtonOne } from '../../../styles/buttons';

const StyledContact = styled(StyledForm)`
	color: ${(p) => p.theme.color.light};
	padding: 3rem 2rem;
	display: none;

	strong {
		color: ${(p) => p.theme.color.light};
		font-size: 20px;
	}

	ul {
		li {
			list-style-type: disc;
		}
		margin-bottom: 16px;
		padding-left: 20px;
	}

	&.active {
		display: block;
	}

	a {
		color: white;
		text-decoration: underline;
	}
	.intro-text {
		line-height: 2;
		margin-bottom: 2rem;
		h3 {
			margin-bottom: 0.75rem;
			font-size: 1.25rem;
		}
		p {
			font-size: 1.125rem;
			margin-bottom: 1rem;
		}
		.quest {
			ul {
				list-style-type: disc;
				padding-left: 2rem;
				li {
					font-size: 1.125rem;
				}
			}
			margin-bottom: 1rem;
			line-height: 1.5;
		}
		h3 {
			font-family: ${(p) => p.theme.font.body};
			font-weight: 700;
		}
		span {
			color: ${(p) => p.theme.color.yellow};
		}
	}

	label {
		color: ${(p) => p.theme.color.light};
	}

	input,
	textarea {
		color: ${(p) => p.theme.color.light};
		padding: 1.25rem 1rem;
		margin-bottom: 2rem;

		&::placeholder {
			font-weight: 400;
			color: ${(p) => p.theme.color.light};
		}

		&:focus {
			box-shadow: 0 0 0 0.125em rgba(255, 216, 0, 0.25);
			border-color: currentColor;
		}
	}

	input {
		border-bottom-color: ${(p) => p.theme.color.light};
	}

	textarea {
		border-color: ${(p) => p.theme.color.light};
	}
`;

const StyledThanks = styled.section`
	padding: 3rem 2rem;

	p {
		text-align: center;
		color: ${(p) => p.theme.color.light};
		font-size: 2.25rem;
		font-family: ${(p) => p.theme.font.display};
		line-height: 1.1;
	}
`;

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

export default ({ type, copy }) => {
	const [inputs, setInputs] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleChange = (e) => {
		setInputs({ ...inputs, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...inputs,
			}),
		})
			.then(setIsSubmitted(true))
			.catch((error) => alert(error));
	};

	if (!!isSubmitted) {
		return (
			<StyledThanks className="has-border">
				<p>
					Thanks for reaching out!
					<br /> We will be in contact shortly.
				</p>
			</StyledThanks>
		);
	} else {
		return (
			<>
				<StyledContact
					name="general"
					method="post"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					onSubmit={handleSubmit}
					className={`has-border${type === 'general' ? ' active' : ''}`}
				>
					{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					<input type="hidden" name="form-name" value="general" />
					<div hidden>
						<label>
							Don’t fill this out:{' '}
							<input name="bot-field" onChange={handleChange} />
						</label>
					</div>
					<div className="columns">
						<div
							className="column intro-text"
							dangerouslySetInnerHTML={{ __html: copy.general }}
						/>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field">
								<label className="label visually-hidden" htmlFor={'name'}>
									Name*
								</label>
								<div className="control">
									<input
										className="input"
										type={'text'}
										name={'name'}
										onChange={handleChange}
										id={'name'}
										required={true}
										placeholder={'Name*'}
									/>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="field">
								<label className="label visually-hidden" htmlFor={'email'}>
									Email*
								</label>
								<div className="control">
									<input
										className="input"
										type={'email'}
										name={'email'}
										onChange={handleChange}
										id={'email'}
										required={true}
										placeholder={'Email*'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field">
								<label className="label visually-hidden" htmlFor={'message'}>
									Message*
								</label>
								<div className="control">
									<textarea
										className="textarea"
										name={'message'}
										onChange={handleChange}
										id={'message'}
										required={true}
										placeholder={'Message*'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field has-text-centered">
								<ButtonOne type="submit">Submit</ButtonOne>
							</div>
						</div>
					</div>
				</StyledContact>
				<StyledContact
					name="vendor"
					method="post"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					onSubmit={handleSubmit}
					className={`has-border${type === 'vendor' ? ' active' : ''}`}
				>
					{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					<input type="hidden" name="form-name" value="vendor" />
					<div hidden>
						<label>
							Don’t fill this out:{' '}
							<input name="bot-field" onChange={handleChange} />
						</label>
					</div>
					<div className="columns">
						<div
							className="column intro-text"
							dangerouslySetInnerHTML={{ __html: copy.vendor }}
						/>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field">
								<label className="label visually-hidden" htmlFor={'name'}>
									Name*
								</label>
								<div className="control">
									<input
										className="input"
										type={'text'}
										name={'name'}
										onChange={handleChange}
										id={'name'}
										required={true}
										placeholder={'Name*'}
									/>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="field">
								<label className="label visually-hidden" htmlFor={'email'}>
									Email*
								</label>
								<div className="control">
									<input
										className="input"
										type={'email'}
										name={'email'}
										onChange={handleChange}
										id={'email'}
										required={true}
										placeholder={'Email*'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field">
								<label className="label visually-hidden" htmlFor={'message'}>
									Message*
								</label>
								<div className="control">
									<textarea
										className="textarea"
										name={'message'}
										onChange={handleChange}
										id={'message'}
										required={true}
										placeholder={'Message*'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field has-text-centered">
								<ButtonOne type="submit">Submit</ButtonOne>
							</div>
						</div>
					</div>
				</StyledContact>
				<StyledContact
					className={`has-border${type === 'distribution' ? ' active' : ''}`}
				>
					<div className="columns">
						<div className="column intro-text">
							<div dangerouslySetInnerHTML={{ __html: copy.distribution }} />
						</div>
					</div>
				</StyledContact>
				<StyledContact
					name="charity"
					method="post"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					onSubmit={handleSubmit}
					className={`has-border${type === 'charity' ? ' active' : ''}`}
				>
					{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					<input type="hidden" name="form-name" value="charity" />
					<div hidden>
						<label>
							Don’t fill this out:{' '}
							<input name="bot-field" onChange={handleChange} />
						</label>
					</div>
					<div className="columns">
						<div
							className="column intro-text"
							dangerouslySetInnerHTML={{ __html: copy.charitable }}
						/>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field">
								<label className="label visually-hidden" htmlFor={'name'}>
									Name*
								</label>
								<div className="control">
									<input
										className="input"
										type={'text'}
										name={'name'}
										onChange={handleChange}
										id={'name'}
										required={true}
										placeholder={'Name*'}
									/>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="field">
								<label className="label visually-hidden" htmlFor={'email'}>
									Email*
								</label>
								<div className="control">
									<input
										className="input"
										type={'email'}
										name={'email'}
										onChange={handleChange}
										id={'email'}
										required={true}
										placeholder={'Email*'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field">
								<label className="label visually-hidden" htmlFor={'message'}>
									Message*
								</label>
								<div className="control">
									<textarea
										className="textarea"
										name={'message'}
										onChange={handleChange}
										id={'message'}
										required={true}
										placeholder={'Message*'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field has-text-centered">
								<ButtonOne type="submit">Submit</ButtonOne>
							</div>
						</div>
					</div>
				</StyledContact>
			</>
		);
	}
};
