import React from 'react';
import styled from 'styled-components';

import map from './images/map.jpg';

const StyledMap = styled.div`
	background-image: url(${map});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 400px;
	height: 100%;
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`;

export default () => (
	<StyledMap>
		<a
			href="https://goo.gl/maps/uAyYp4ufMvryztzL7"
			target="_blank"
			rel="noopener noreferrer"
		></a>
	</StyledMap>
);
