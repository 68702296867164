import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';
import Hero from '../components/contact/hero';
import Divide from '../components/shared/divide';
import Map from '../components/contact/map';
import ContactDetails from '../components/contact/contact-details';

const StyledDivide = styled.div`
	[class*='StyledImg'] {
		margin-bottom: 0;
		margin-top: -1rem;
	}
`;

const MapContactSection = styled.section`
	.map-column {
		padding: 0;
	}

	.details-column {
		background-color: ${(p) => p.theme.color.yellow};
	}
`;

export const query = graphql`
	query {
		prismicContactPage {
			prismicId
			data {
				general_inquiry_message {
					html
				}
				vendor_inquiry_message {
					html
				}
				distribution_inquiry_message {
					html
				}
				charitable_request_message {
					html
				}
				contact_details {
					html
				}
				hours {
					html
				}
				details {
					html
				}
			}
		}
	}
`;

const PageTemplate = ({ data }) => (
	<Layout>
		<SEO title="Contact" />
		<h1 className="visually-hidden">Contact The Flying Monkeys</h1>
		<Hero
			copy={{
				general: data.prismicContactPage.data.general_inquiry_message.html,
				vendor: data.prismicContactPage.data.vendor_inquiry_message.html,
				distribution:
					data.prismicContactPage.data.distribution_inquiry_message.html,
				charitable:
					data.prismicContactPage.data.charitable_request_message.html,
			}}
		/>
		<StyledDivide>
			<Divide />
		</StyledDivide>
		<MapContactSection className="columns">
			<div className="column map-column">
				<Map />
			</div>
			<div className="column details-column">
				<ContactDetails
					contact={data.prismicContactPage.data.contact_details.html}
					hours={data.prismicContactPage.data.hours.html}
					details={data.prismicContactPage.data.details.html}
				/>
			</div>
		</MapContactSection>
	</Layout>
);

export default withPreview(PageTemplate);
