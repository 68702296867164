import styled from 'styled-components';

export default styled.form`
	input {
		background: transparent;
		border: none;
		border-radius: 0;
		border-bottom-width: 1px;
		border-bottom-style: solid;
	}

	textarea {
		background: transparent;
		border-width: 1px;
		border-style: solid;
	}
`;
