import React from 'react';
import styled from 'styled-components';

const StyledContactDetails = styled.div`
	color: ${(p) => p.theme.color.dark};
	strong {
		color: ${(p) => p.theme.color.dark};
	}
	h3 {
		font-family: ${(p) => p.theme.font.body};
		font-weight: 700;
	}

	address {
		font-style: normal;
	}

	div {
		margin-bottom: 1.5rem;
	}

	.note {
		h3 {
			display: inline;
		}

		p {
			display: inline;
		}
	}
	padding: 2rem;
	@media (min-width: 769px) {
		padding: 3rem 4rem;
	}
`;

export default ({ contact, hours, details }) => (
	<StyledContactDetails>
		<div className="address">
			<div dangerouslySetInnerHTML={{ __html: contact }} />
		</div>
		<div className="tap-room">
			<div dangerouslySetInnerHTML={{ __html: hours }} />
		</div>
		<div className="note">
			<div dangerouslySetInnerHTML={{ __html: details }} />
		</div>
	</StyledContactDetails>
);
