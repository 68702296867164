import styled from 'styled-components';

import buttonOneBg from './images/button-one-bg.svg';

export const ButtonOne = styled.button`
	font-family: ${(p) => p.theme.font.display};
	font-size: 1.5rem;
	font-weight: 400;
	letter-spacing: 0.1em;
	color: ${(p) => p.theme.color.light} !important;
	text-transform: uppercase;
	background: none;
	background-image: url(${buttonOneBg});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border: none;
	padding: 1em 2em;
	a {
		color: ${(p) => p.theme.color.light} !important;
	}
	transition: all ease 300ms;
	&:hover {
		color: white !important;
		transform: scale(1.05);
	}
`;
